<template>
  <div class="py-2 container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card mt-4">
          <div class="pb-0 card-header">
            <div class="d-lg-flex justify-content-between align-items-center">
              <div>
                <h5 class="mb-0">Comissões</h5>
                <p class="mb-0 text-sm">
                  Comissões dos closers/representantes
                </p>
              </div>
              <span class="text-sm font-weight-bolder">
                Mês: {{ filters.formatDate(mes) }}
              </span>
            </div>
          </div>
          <div class="px-0 pb-0 card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="container-closers">
                  <comissao-closer-card v-for="closer of closers" :closer="closer" :key="closer.id"
                    :selected="isCloserSelected(closer)" @click="onCloserSelect(closer)" />
                </div>
              </div>
            </div>
            <hr>
            <div class="d-flex justify-content-between align-items-center p-2">
              <div>
                Legenda:
                <span class="badge bg-success-soft span-legenda">1~10</span>
                <span class="badge bg-info-soft span-legenda">11~20</span>
                <span class="badge bg-warning-soft span-legenda">21~30</span>
              </div>
              <div class="my-auto ms-auto" v-if="selectedCloserVendas.length > 0">
                <button class="mt-0 mb-0 btn btn-outline-success btn-sm export mt-sm-0" type="button" name="button"
                  @click="exportData()">
                  Exportar CSV
                </button>
              </div>
            </div>
            <div class="p-0 table-responsive">
              <table id="comissao-closer-list" class="table table-flush">
                <thead>
                  <tr>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                      Cliente
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Data da Venda
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Operação
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-right">
                      Valor
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-center">
                      Situação Financeira
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-right">
                      Comissão
                    </th>
                    <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-right">
                      Comissão
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item of selectedCloserVendas" :key="item" :class="getClassByDate(item.data_contrato)">
                    <td class="text-sm">
                      {{ item.nome_fantasia.toUpperCase() }}
                    </td>
                    <td class="text-sm text-center text-dark">
                      {{ filters.formatDate(item.data_contrato) }}
                    </td>
                    <td class="text-sm text-center">
                      <badge-tipo-comissao :tipo="item.tipo" />
                    </td>
                    <td class="text-sm text-right font-weight-bold">
                      {{ filters.currency(item.valor) }}
                    </td>
                    <td class="text-sm text-center font-weight-bold">
                      <badge-status-financeiro :status="item.status" :faturas="item.faturas" />
                    </td>
                    <td class="text-sm text-right font-weight-bold text-primary">
                      {{ item.percentual }}%
                    </td>
                    <td class="text-sm text-right font-weight-bold text-dark">
                      {{ filters.currency(item.comissao) }}
                    </td>
                  </tr>
                  <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th class="text-right">
                      Total Comissão:
                    </th>
                    <th class="text-md text-right font-weight-bold text-dark">
                      {{ filters.currency(totalComissao) }}
                    </th>
                  </tr>
                </tbody>
                <tfoot>

                </tfoot>
              </table>
              <h5 class="mb-2 text-center">Totais por tipo de venda</h5>
              <div>
                <div class="d-lg-flex justify-content-between align-items-center">
                  <div>
                  </div>
                </div>
                <table class="table table-flush">
                  <thead>
                    <tr>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                        Tipo
                      </th>
                      <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 text-right">
                        Valor
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, key) in totaisTipoComissao" :key="key">
                      <td>
                        <badge-tipo-comissao :tipo="key" />
                      </td>
                      <td class="text-sm text-right font-weight-bold text-dark">
                        {{ filters.currency(item) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ComissaoCloserCard from "./components/ComissaoCloserCard.vue";
import BadgeTipoComissao from "./components/BadgeTipoComissao.vue";
import BadgeStatusFinanceiro from "./components/BadgeStatusFinanceiro.vue";
import api from "@/services/api";
import moment from "moment";
import { exportToCSV } from "@/utils/export";

export default {
  name: "Comissoes",
  components: {
    ComissaoCloserCard,
    BadgeTipoComissao,
    BadgeStatusFinanceiro,
  },
  created() {
    const { mes } = this.$route.params;

    this.mes = mes;

    this.fetchData();
  },
  data() {
    return {
      mes: '',
      closers: [],
      closersVendas: {},
      selectedCloser: null,
      selectedCloserVendas: [],
    };
  },
  methods: {
    onCloserSelect(closer) {
      this.selectedCloser = closer;

      this.fetchCloserVendas();
    },
    exportData() {
      const { nome } = this.selectedCloser;
      const mes = moment(this.mes).format('YYYY-MM');
      const closerName = this.filters.slug(nome + ' ' + mes);
      exportToCSV('comissao-closer-list', `comissoes-${closerName}.csv`);
    },
    fetchCloserVendas() {
      const { selectedCloser } = this;

      const mes = moment(this.mes).format('YYYY-MM-DD');

      const closerVendas = this.closersVendas[selectedCloser.id];

      if (closerVendas) {
        this.selectedCloserVendas = closerVendas;
        return;
      }

      api.get(`/metas/vendas-closer/${selectedCloser.id}`, {
        params: {
          mes,
        }
      }).then(response => {
        const { data } = response;

        this.setCloserVendas(data);
      });
    },
    getPercentualMrr() {
      if (!this.selectedCloser) return 0;

      const { percentualMeta } = this.selectedCloser.vendas;

      if (percentualMeta >= 150) {
        return 100;
      }

      if (percentualMeta >= 100) {
        return 80;
      }

      if (percentualMeta >= 80 && percentualMeta <= 99) {
        return 50;
      }

      if (percentualMeta >= 60 && percentualMeta <= 79) {
        return 30;
      }

      return 0;

    },
    getPercentualConsultoria() {
      if (!this.selectedCloser) return 0;

      const { percentualMeta } = this.selectedCloser.vendas;

      if (percentualMeta >= 150) {
        return 8;
      }

      if (percentualMeta >= 100) {
        return 5;
      }

      if (percentualMeta >= 80 && percentualMeta <= 99) {
        return 3;
      }

      if (percentualMeta >= 60 && percentualMeta <= 79) {
        return 2;
      }

      return 0;

    },
    getPercentualImpostos() {
      return 0;
    },
    setCloserVendas(data) {
      const percentualMrr = this.getPercentualMrr();
      const percentualConsultoria = this.getPercentualConsultoria();
      const percentualImpostos = this.getPercentualImpostos();

      const vendas = data.map(venda => {
        const { tipo } = venda;
        const valorVenda = (venda.valor * (1 - percentualImpostos / 100))
        let percentual = percentualMrr;
        let comissao = 0.0;

        if (['mrr', 'arr', 'upsell'].includes(tipo)) {

          comissao = valorVenda * (percentualMrr / 100);
        }

        if (['consultoria', 'upsell_consultoria'].includes(tipo)) {
          percentual = percentualConsultoria;
          comissao = valorVenda * (percentualConsultoria / 100);
        }

        return {
          ...venda,
          percentual,
          percentualImpostos,
          comissao,
          status: 'verificando...',
          faturas: [],
        };
      });

      this.closersVendas[this.selectedCloser.id] = vendas;

      this.selectedCloserVendas = vendas;

      this.getFaturasEmAbertoClientesCloser(this.selectedCloser.id);
    },
    isCloserSelected(closer) {
      return closer.id === this.selectedCloser?.id;
    },
    async fetchData() {
      try {
        const mes = moment(this.mes).format('YYYY-MM-DD');

        const { data } = await api.get(`/metas/individual-closer`, {
          params: {
            mes
          },
        });

        this.closers = data;

        this.onCloserSelect(data[0]);
      } catch (error) {
        console.error(error);
      }
    },
    getClassByDate(dataContrato) {
      const data = moment(dataContrato).date();

      if (data <= 10) {
        return 'bg-success-soft';
      }

      if (data <= 20) {
        return 'bg-info-soft';
      }

      return 'bg-warning-soft';
    },
    getFaturasEmAbertoClientesCloser(closerId) {
      const conexaClienteIds = this.closersVendas[closerId].map(venda => venda.conexa_cliente_id);
      const ids = [];

      for (const cliente of conexaClienteIds) {
        if (ids.includes(cliente)) {
          continue;
        }

        ids.push(cliente);
      }

      api
        .get(`/clientes/faturas-aberto?ids=${ids.join(',')}`)
        .then(response => {
          const { data } = response;

          const closerVendas = this.closersVendas[closerId];

          for (const venda of closerVendas) {
            const faturas = data[venda.conexa_cliente_id] ? data[venda.conexa_cliente_id].faturas : [];

            if (faturas.length > 0) {
              venda.faturas = faturas;
              venda.status = 'inadimplente';
              continue;
            }

            venda.faturas = [];
            venda.status = 'em dia';
          }

        }).catch(error => {
          console.error(error);
        });
    }
  },
  computed: {
    totalComissao() {
      return this.selectedCloserVendas.reduce((acc, item) => acc + item.comissao, 0);
    },
    totaisTipoComissao() {
      if (!this.selectedCloserVendas.length) return {};

      const tipos = {};

      this.selectedCloserVendas.forEach(venda => {
        const { tipo, valor } = venda;

        if (!tipos[tipo]) {
          tipos[tipo] = 0;
        }

        tipos[tipo] += Number(valor);
      });

      return tipos;
    }
  },
};
</script>
<style lang="scss" scoped>
.container-closers {
  padding: 1em;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.span-legenda {
  border: 0.6px solid #67748e;
  margin-right: 8px;
  color: #67748e;

}
</style>
