<template>
  <div class="card">
    <div class="pb-0 card-header mb-0">
      <div class="d-flex justify-content-between">
        <button type="button" class="btn btn-outline-success btn-xs mb-0" @click="previous()">
          <i class="fas fa-chevron-left"></i> Ant
        </button>
        <h6 class="mb-2 text-uppercase">
          {{ filters.formatDate(currentMonth, 'MMMM - YYYY') }}
        </h6>
        <button type="button"
          :class="['btn', isNextVisible ? 'btn-outline-success' : 'btn-outline-secondary', 'btn-xs mb-0']"
          :disabled="!isNextVisible" @click="next()">
          Próx <i class="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
    <div class="card-body pb-0 pt-1">
      <div class="table-responsive">
        <table class="table mb-0">
          <tbody>
            <tr v-for="item in totalMensal" :key="item.tipo">
              <td class="align-middle">
                <router-link :to="item.href && item.valor ? item.href : '#'">
                  <h6 :class="['mb-0 text-xs', item.href && item.valor > 0 ? 'text-underline' : 'no-link']">
                    {{ getTitle(item) }}
                  </h6>
                </router-link>
              </td>
              <td class="align-middle text-center">
                <progress-bar-meta :item="item" />
              </td>
              <td class="text-right">
                <p :class="['font-weight-bold mb-0', `text-${item.className}`]">
                  <span>
                    {{ $store.state.toggleValores ? filters.currencyWithPrefix(item.valor) : 'R$ -----' }}
                  </span>
                </p>
              </td>
            </tr>
            <tr>
              <td class="text-right link" colspan="3" data-bs-toggle="modal" data-bs-target="#modal-chat-ia"
                @click="openIaModal()">
                <h6 class="mb-0 text-xs">
                  PERGUNTE PARA I.A.
                  <img src="@/assets/img/ai.png" alt="IA" style="width: 25; height: 25px;" />
                </h6>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div id="modal-chat-ia" class="modal fade" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog mt-lg-6 modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 id="ModalLabel" class="modal-title">
            Pergunte para I.A.
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="chat-container">
            <div class="messages" id="messagesContainer">
              <div v-for="(message, index) in messages" :key="index"
                :class="['message', message.from === 'IA' ? 'ai-message' : 'user-message']">
                <p>
                  <strong>
                    <img src="@/assets/img/ai.png" alt="IA" style="width: 20; height: 20px;"
                      v-if="message.from === 'IA'" /> &nbsp; {{ message.from }}:
                  </strong>
                </p>
                <span class="message-text">
                  {{ message.animatedText }}
                </span>
              </div>
            </div>
            <!-- Exibir "IA está digitando..." -->
            <div v-if="isTyping" class="typing-indicator">
              IA está digitando
              <span class="dot">.</span><span class="dot">.</span><span class="dot">.</span>
            </div>
          </div>
          <input v-model="question" placeholder="Digite sua pergunta..." @keyup.enter="sendQuestion"
            :disabled="isTyping" ref="questionInput" />
        </div>
        <div class="modal-footer d-flex flex-row-reverse justify-content-between">
          <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
            Fechar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue';
import * as moment from 'moment';
import api from './../../../services/api';
import ProgressBarMeta from '@/views/pages/components/ProgressBarMeta.vue';

export default {
  name: "VendasMensalCard",
  components: {
    ProgressBarMeta
  },
  data() {
    return {
      totalMensal: [],
      isNextVisible: false,
      currentMonth: moment()
        .startOf('month')
        .toDate(),
      isTyping: false,
      question: '',
      messages: [],
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    next() {
      this.currentMonth = moment(this.currentMonth).add(1, 'month');
      this.fetchData();
    },
    previous() {
      this.currentMonth = moment(this.currentMonth).subtract(1, 'month');
      this.fetchData();
    },
    fetchData() {
      const dataReferencia = moment(this.currentMonth).format('YYYY-MM-DD');

      api.get('/dashboard-gerencial/total-mensal', {
        params: {
          data: dataReferencia
        }
      }).then(response => {
        const { data } = response;
        this.totalMensal = data;
      }).catch(e => console.log(e));
    },
    getTitle(item) {
      const { tipo, quantidade } = item;
      if (tipo === 'RESULTADO') {
        return tipo;
      }

      return `${tipo} (${quantidade})`;
    },
    openIaModal() {
      if (!this.messages.length) {
        this.messages.push({ from: 'IA', text: 'Olá, em que posso ajudar?', animatedText: 'Olá, em que posso ajudar?' });
        this.typeEffect('Olá Sou seu assistente de I.A, em que posso ajudar?', this.messages.length - 1);
      }
    },
    async sendQuestion() {
      if (!this.question) {
        return;
      }

      this.messages.push({
        from: 'Você',
        text: this.question,
        animatedText: this.question
      });

      this.isTyping = true; // Ativar indicador de digitação

      try {
        const message = this.question;

        this.question = ''; // Limpar campo de pergunta        

        const response = await api.post('/ia/answer-message', { message });

        // Criando mensagem vazia para a IA e adicionando efeito de digitação
        const aiMessage = {
          from: 'IA',
          text: response.data.result,
          animatedText: ''
        };

        this.messages.push(aiMessage);

        // Simular tempo de resposta antes da digitação começar
        setTimeout(() => {
          this.typeEffect(response.data.result, this.messages.length - 1);
          this.isTyping = false; // Remover "IA está digitando..."

          setTimeout(() => {
            this.$refs.questionInput.focus();
          }, 50);
        }, 300);


      } catch (error) {
        this.messages.push({ from: 'IA', text: 'Erro ao processar a pergunta', animatedText: 'Erro ao processar a pergunta, tente novamente...' });
        this.isTyping = false; // Remover "IA está digitando..."
      }
      this.scrollToBottom();

    },
    typeEffect(fullText, index) {
      let i = 0;
      this.messages[index].animatedText = ''; // Iniciar o texto vazio
      const typingInterval = setInterval(async () => {
        if (i < fullText.length) {
          this.messages[index].animatedText += fullText[i];
          i++;
          await nextTick();
          this.scrollToBottom();
        } else {
          this.scrollToBottom();
          clearInterval(typingInterval); // Para o efeito quando terminar          
        }
      }, 10); // Tempo entre caracteres (ajuste conforme necessário)
    },
    scrollToBottom() {
      const scrollableDiv = document.getElementById('messagesContainer');
      // Scroll to the bottom of the div
      scrollableDiv.scrollTop = scrollableDiv.scrollHeight + scrollableDiv.clientHeight;
    }
  },
  watch: {
    currentMonth: function (newMonth) {
      const currentMonth = moment()
        .startOf('month')
        .toDate();
      const isNextVisible = moment(currentMonth).isAfter(newMonth);
      this.isNextVisible = isNextVisible;
    },

  }
};
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
}

.text-underline {
  text-decoration: underline;
}

.link {
  cursor: pointer;
}

.no-link {
  cursor: initial;
}

.chat-container {
  width: 100%;
  margin: auto;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

/* Área de mensagens */
.messages {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 400px;
  max-height: 400px;
  overflow-y: scroll;
  padding-bottom: 10px;
}

/* Estilos das mensagens */
.message {
  padding: 10px;
  border-radius: 12px;
  max-width: 70%;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  opacity: 0;
  animation: fadeIn 0.1s forwards;
  white-space: pre-wrap;
}

/* Animação para mensagens novas */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mensagens do usuário (direita) */
.user-message {
  background: #0078FF;
  color: white;
  align-self: flex-end;
  text-align: right;
  margin-right: 10px;
}

/* Mensagens da IA (esquerda) */
.ai-message {
  background: #E5E5E5;
  color: black;
  align-self: flex-start;
}

/* Texto das mensagens */
.message-text {
  margin: 5px 0 0 0;
  font-size: 14px;
}

.typing-indicator {
  font-style: italic;
  color: gray;
  align-self: flex-start;
}

.dot {
  animation: blink 1s infinite;
}

@keyframes blink {

  0%,
  100% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
}
</style>
