<template>
  <div class="card h-100">
    <div class="p-2 pb-0 card-header">
      <div class="d-flex justify-content-center">
        <h6 class="mb-0">{{ title }}</h6>
      </div>
    </div>
    <div class="pb-2 mt-0 card-body">
      <nenhum-registro-encontrado :hidden="seriesLocal.length > 0" />
      <div class="row" :hidden="seriesLocal.length == 0">
        <div class="text-start col-12">
          <div class="chart">
            <canvas :id="chartId" class="chart-canvas" height="140"></canvas>
          </div>
        </div>
        <div class="my-auto col-6" v-for="(serie, index) of seriesLocal" :key="index">
          <span  class="badge badge-md badge-dot me-0 d-block text-start">
            <i :style="{ backgroundColor: serie.color }" class="badge-dot"></i>
            <span class="text-xs text-dark chart-span-legenda">
              {{ serie.label?.toUpperCase() }} - {{ getValueLabel(serie.value) }}
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NenhumRegistroEncontrado from "@/components/NenhumRegistroEncontrado.vue";
import { randomInt } from "@/utils/number";
import Chart from "chart.js/auto";
import * as uuid from 'uuid';

export default {
  name: "PizzaNpsCard",
  components: {
    NenhumRegistroEncontrado,
  },
  props: {
    title: {
      type: String,
      default: "Traffic Channels",
    },
    isPercentual: {
      type: Boolean,
      default: false,
    },
    series: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.chartId = `chart-pie_${uuid.v4()}`;
  },
  data() {
    return {
      chartId: "chart-pie",
      seriesLocal: [],
    };
  },
  methods: {
    getValueLabel(value) {
      return `${value}${this.isPercentual ? '%' : ''}`;
    },
    creatChart() {
      if (this.series.length === 0) {
        return;
      }

      this.seriesLocal = this.series.map((serie) => {
        return {
          ...serie,
          color: this.geraCorAleatoria(serie.label),
        };
      });

      var pieChart = document.getElementById(this.chartId).getContext("2d");

      let chartStatus = Chart.getChart(this.chartId);

      if (chartStatus != undefined) {
        chartStatus.destroy();
      }

      const random = randomInt(100, 300);

      setTimeout(() => {
        const labels = this.seriesLocal.map((serie) => serie.label);
        const colors = this.seriesLocal.map((serie) => serie.color);
        const values = this.seriesLocal.map((serie) => serie.value);

        // Pie chart
        new Chart(pieChart, {
          type: "pie",
          data: {
            labels,
            datasets: [
              {
                label: 'Quantidade',
                weight: 9,
                cutout: 0,
                tension: 0.9,
                pointRadius: 2,
                borderWidth: 2,
                backgroundColor: colors,
                data: values,
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            interaction: {
              intersect: false,
              mode: "index",
            },
            scales: {
              y: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
              x: {
                grid: {
                  drawBorder: false,
                  display: false,
                  drawOnChartArea: false,
                  drawTicks: false,
                },
                ticks: {
                  display: false,
                },
              },
            },
          },
        });
      }, random);
    },
    geraCorAleatoria(label) {

      if(label === 'detratores' || label === 'neutros' || label === 'promotores') {
        const colors = {
          detratores: '#f5365c',
          neutros: '#FBD38D',
          promotores: '#2dce89',
        };
        return `${colors[label]}95`;
      }

      return '#' + Math.floor(Math.random() * 16777215).toString(16).padStart(6, '0');
    }
  },
  watch: {
    series: {
      handler() {
        this.creatChart();
      },
      deep: true,
    }
  },
};
</script>
<style lang="scss" scoped>
  .chart-span-legenda {
    display: inline-block;
    max-width: 125px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle; 
    cursor: pointer;
  }

  .chart-span-legenda:hover {
    white-space: nowrap;
    overflow: visible;
    text-overflow: unset;
    max-width: 100%;
    font-weight: bold;
  }
</style>