<template>
  <div :class="['mt-2 col-lg-3 col-md-6 col-sm-12 mt-sm-2', onClick != null ? 'has-details' : '']" @click="onClick">
    <div class="card">
      <div class="p-3 card-body position-relative">
        <div class="row">
          <div class="col-7 text-start">
            <p class="text-sm mb-1 text-uppercase font-weight-bold">
              {{ title }}
            </p>
            <h5 class="mb-0 font-weight-bolder">{{ $store.state.toggleValores ? count : 'R$ -----' }}</h5>
          </div>
          <div class="col-5">
            <div class="dropdown text-end">
              <a id="dropdownUsers1" href="javascript:;" class="cursor-pointer text-secondary"
                :class="{ show: showMenu }" data-bs-toggle="dropdown" aria-expanded="false"
                @click="showMenu = !showMenu">
                <span class="text-xs text-secondary">{{ menu }}</span>
              </a>
              <ul class="px-2 py-3 dropdown-menu dropdown-menu-end" :class="{ show: showMenu }"
                aria-labelledby="dropdownUsers1">
                <li v-for="(drop, index) in dropdown" :key="index">
                  <a class="dropdown-item border-radius-md" :href="drop.route">{{ drop.label }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotaisVendasCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    count: {
      type: String,
      default: "",
    },
    menu: {
      type: String,
      default: "",
    },
    dropdown: {
      type: Array,
      default: () => [],
      label: String,
      route: String,
    },
    onClick: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.has-details {
  cursor: pointer;
}
</style>
