<template>
	<div class="card h-100">
		<div class="p-3 pb-0 card-header">
			<div class="d-flex justify-content-between">
				<h6 class="mb-0">
					Metas individuais
				</h6>
				<argon-button type="button" @click="generateCommission()" color="primary" size="sm" class="my-4 mb-2">
					APURAR COMISSÃO
				</argon-button>
			</div>
		</div>
		<div class="p-3 card-body">
			<div class="table-responsive">
				<table class="table align-items-center mb-0">
					<thead>
						<tr>
							<th class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7">
								Closer
							</th>
							<th class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2 text-center">
								MRR
							</th>
							<th class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2 text-center">
								ARR
							</th>
							<th class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2 text-center">
								MRR+(ARR/12)
							</th>
							<th class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2 text-center">
								Consultoria
							</th>
							<th class="text-uppercase text-dark text-xxs font-weight-bolder opacity-7 ps-2 text-center">
								Super Meta
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="closer of closers" :key="closer.id">
							<td>
								<div class="my-auto">
									<h6 class="mb-0 text-xs">
										{{ filters.firstAndLastName(closer.nome) }}
									</h6>
								</div>
							</td>
							<td class="align-middle text-center">
								<div class="d-flex align-items-center flex-column">
									<div class="d-flex justify-content-between">
										<p class="text-xs font-weight-bolder mb-1">
											{{ $store.state.toggleValores ?
												filters.currency(closer.vendas.mrr) : '----' }}/<small>{{
												valorMrr
											}}</small>
										</p>
									</div>
									<div>
										<progress-bar :percentage="closer.vendas.percentualMrr" />
									</div>
								</div>
							</td>
							<td class="align-middle text-center">
								<div class="d-flex align-items-center flex-column">
									<div class="d-flex justify-content-between">
										<p class="text-xs font-weight-bolder mb-1">
											{{ $store.state.toggleValores ?
												filters.currency(closer.vendas.arr) : '----' }}/<small>{{
												$store.state.toggleValores ?
													filters.currency(closer.valorTotalArr) : '----'
											}}</small>
										</p>
									</div>
									<div>
										<progress-bar :percentage="closer.vendas.percentualArr" />
									</div>
								</div>
							</td>
							<td class="align-middle text-center">
								<div class="d-flex align-items-center flex-column">
									<div class="d-flex justify-content-between">
										<p class="text-xs font-weight-bolder mb-1">
											{{ $store.state.toggleValores ?
												filters.currency(closer.vendas.total) : '----' }}/<small>{{
												$store.state.toggleValores ? filters.currency(closer.valorTotal)
													: '----'
											}}</small>
										</p>
									</div>
									<div>
										<progress-bar :percentage="closer.vendas.percentualMeta" />
									</div>
								</div>
							</td>
							<td class="align-middle text-center">
								<div class="d-flex align-items-center flex-column">
									<div class="d-flex justify-content-between">
										<p class="text-xs font-weight-bolder mb-1">
											{{ $store.state.toggleValores ?
												filters.currency(closer.vendas.consultoria) : '----' }}/<small>{{
												$store.state.toggleValores ?
													filters.currency(closer.valorConsultoria) : '----'
												}}</small>
										</p>
									</div>
									<div>
										<progress-bar :percentage="closer.vendas.percentualConsultoria" />
									</div>
								</div>
							</td>
							<td class="align-middle text-center">
								<div class="d-flex align-items-center flex-column">
									<div class="d-flex justify-content-between">
										<p class="text-xs font-weight-bolder mb-1">
											{{ $store.state.toggleValores ?
												filters.currency(closer.vendas.total) : '----' }}/<small>{{
												$store.state.toggleValores ?
													filters.currency(closer.valorSuperMeta) : '----'
											}}</small>
										</p>
									</div>
									<div>
										<progress-bar :percentage="closer.vendas.percentualSuperMeta" />
									</div>
								</div>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</template>
<script>
import ProgressBar from "./ProgressBar.vue";
import api from "@/services/api";
import moment from "moment";
import ArgonButton from "@/components/ArgonButton.vue";

export default {
	name: "MetasIndividuaisCard",
	components: {
		ProgressBar,
		ArgonButton
	},
	props: {
		mes: {
			type: [String, Date],
			default: ""
		},
	},
	data() {
		return {
			closers: []
		}
	},
	created() {
		this.fetchData();
	},
	methods: {
		fetchData() {
			const mes = moment(this.mes).format('YYYY-MM-DD');

			api.get(`/metas/individual-closer`, {
				params: {
					mes: mes
				}
			}).then(({ data }) => {
				this.closers = data;
			}).catch(error => {
				console.log(error);
			})
		},
		percentageColor(percentage) {
			if (percentage >= 90) {
				return 'bg-success';
			} else if (percentage >= 50) {
				return 'bg-info';
			} else {
				return 'bg-danger';
			}
		},
		percentageColorLabel(percentage) {
			if (percentage >= 90) {
				return 'text-success';
			} else if (percentage >= 50) {
				return 'text-info';
			} else {
				return 'text-danger';
			}
		},
		generateCommission() {
			const mes = moment(this.mes).format('YYYY-MM-DD');

			this.$router.push({
				name: 'Comissões',
				params: {
					mes
				}
			});

			// api.post(`/metas/individual-closer/comissao`, {
			// 	mes: mes
			// }).then(({ data }) => {
			// 	this.$toast.success(data.message);
			// }).catch(error => {
			// 	console.log(error);
			// })
		}
	},
	watch: {
		mes: {
			handler() {
				this.fetchData();
			},
			deep: true
		}
	},
	calculated: {
		valorMrr() {
			return this.$store.state.toggleValores ?
				this.filters.currency(this.closer.valorTotalMrr) : '----';
		}
	}
}
</script>
<style lang="scss" scoped>
.progress {
	height: 6px !important;
}
</style>