<template>
	<div class="card card-contratos-ativos">
		<div class="pb-0 card-header mb-0">
			<header-months :current-month="currentMonth" :on-change-date="onChangeDate" />
		</div>
		<div class="p-1 card-body">
			<div class="row">
				<div class="my-auto col-12 text-center">
					<div class="numbers">
						<p class="mb-0 text-sm text-secondary text-capitalize font-weight-bold opacity-7">
							Contratos Ativos -
							<span class="text-dark font-weight-bolder">
								{{ $store.state.toggleValores ? total.quantidade : '---' }}
							</span>
						</p>
						<h3 class="mb-0 text-dark font-weight-bolder">
							{{ $store.state.toggleValores ? 
								total ? filters.currencyWithPrefix(total.total) : 0 : 
								'R$ -----' 
							}}
						</h3>
						<div v-if="total"
							class="mb-0 text-sm d-flex align-items-center justify-content-between text-capitalize font-weight-bold">
							<div title="Mensal">
								<span class="text-dark">
									MRR({{$store.state.toggleValores ? total.quantidadeMrr : '---' }}):
								</span>
								<span class="text-dark font-weight-bolder">
									{{ $store.state.toggleValores ? filters.currency(total.totalMrr) : 'R$ -----' }}
								</span>
							</div>
							<div title="Trimestral">
								<span class="text-dark">
									TRR({{ $store.state.toggleValores ? total.quantidadeTrr : '---' }}):
								</span>
								<span class="text-dark font-weight-bolder">
									{{ $store.state.toggleValores ? filters.currency(total.totalTrr) : 'R$ -----' }}
								</span>
							</div>
							<div v-if="isCurrentMonth">
								<span class="text-info">
									CONGELADOS({{ $store.state.toggleValores ? total.quantidadeCongelado : '---' }}):
								</span>
								<span class="text-info font-weight-bolder">
									{{ $store.state.toggleValores ? filters.currency(total.totalCongelado) : 'R$ -----' }}
								</span>
							</div>
							<div title="Semestral">
								<span class="text-dark">
									SRR({{ $store.state.toggleValores ? total.quantidadeSrr : '---' }}):
								</span>
								<span class="text-dark font-weight-bolder">
									{{ $store.state.toggleValores ? filters.currency(total.totalSrr) : 'R$ -----' }}
								</span>
							</div>
							<div title="Anual">
								<span class="text-dark">
									ARR({{ $store.state.toggleValores ? total.quantidadeArr : '---' }}):
								</span>
								<span class="text-dark font-weight-bolder">
									{{ $store.state.toggleValores ? filters.currency(total.totalArr) : 'R$ -----' }}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import HeaderMonths from './HeaderMonths.vue';

export default {
	name: "TotalContratosAtivosCard",
	components: {
		HeaderMonths
	},
	props: {
		total: {
			type: Object,
			default: () => { }
		},
		onChangeDate: {
			type: Function,
			required: true
		},
		currentMonth: {
			type: Date,
			required: true
		}
	},
	computed: {
		isCurrentMonth() {
			return this.currentMonth.getMonth() === new Date().getMonth();
		}
	}
};
</script>
<style lang="scss" scoped>
.card-contratos-ativos {
	.card-body {
		padding: 0.5em 1.0rem !important
	}
}
</style>
