import { infoToast } from './alerts';

export const exportToCSV = (tableName, fileName = 'table.csv', delimiter = ';') => {
    console.log('Exporting table to CSV...');

    const tableData = Array.from(document.getElementById(tableName).rows);

    if (tableData.length <= 1) {
        infoToast('Nenhum dado para exportar.', 'Exportar');
        return;
    }

    const csvRows = [];

    // Create the data rows
    tableData.forEach((row) => {
        const columns = row.querySelectorAll('td,th');
        const values = [];
        for (const column of columns) {
            if (column.hasAttribute('ignore-exportable')) {
                continue;
            }
            const value =
                (column.textContent ?? '')
                    .replace(new RegExp(delimiter, 'g'), '.')
                    .replace(/\n/g, ' ');

            values.push(value);

        }

        csvRows.push(values.join(delimiter));
    });

    // Join all rows with line breaks
    const csvContent = csvRows.join('\n');

    // Create a Blob object and download the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
}

export const exportToExcel = (tableName, fileName = 'table.xlsx') => {
    console.log('Exporting table to Excel...');

    const tableData = document.getElementById(tableName).rows;

    if (tableData.length <= 1) {
        infoToast('Nenhum dado para exportar.', 'Exportar');
        return;
    }

    const tableHeader = tableData[0].querySelectorAll('td,th');

    const headerRow = [];

    for (let i = 0; i < tableHeader.length; i++) {
        headerRow.push(tableHeader[i].innerHTML);
    }

    const dataRows = [];

    // Create the data rows
    tableData.forEach((row) => {
        const values = Object.values(row).join(',');
        dataRows.push(values);
    });

    const csvContent = [headerRow, ...dataRows].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();

    document.body.removeChild(link);
}

export const exportDataTable = (dataTable, fileName = 'table.csv', delimiter = ';') => {
    const { data } = dataTable;
    //const headers = data.headings.filter((column, index) => column.).join(delimiter);
    const hiddenColumns = [];

    for (let index = 0; index < data.headings.length; index++) {
        const header = data.headings[index];

        if (eval(header.attributes['exportable']) == false) {
            hiddenColumns.push(index);
        }
    }

    const lines = [];

    const headers = data.headings
        .filter((a, index) => !hiddenColumns.includes(index))
        .map((header) => header.data)
        .join(delimiter);

    lines.push(headers);

    const rows = data.data
        .map((row) => {
            const { cells } = row;

            return cells
                .filter((a, index) => !hiddenColumns.includes(index))
                .map((cell) => cell.text ?? cell.data)
                .join(delimiter);
        });

    lines.push(...rows);
    // Create a link to trigger the download
    const link = document.createElement("a")
    link.href = encodeURI(`data:text/csv;charset=utf-8,${lines.join('\n')}`)
    link.download = `${fileName || "datatable_export"}.csv`
    document.body.appendChild(link)
    link.click();
    document.body.removeChild(link)

}
