<template>
  <div class="mt-4 mt-sm-0">
    <div class="card">
      <div class="p-3 card-body position-relative">
        <div class="row">
          <div class="col-12 text-start">
            <p class="text-sm mb-1 text-uppercase font-weight-bold">
              Avaliações
            </p>
            <h5 class="mb-0 font-weight-bolder">
              {{ $store.state.toggleValores ? count : '----' }}
                /{{ $store.state.toggleValores ? possiveis : '----' }}
              <small class="text-xs">
                ({{ $store.state.toggleValores ? percentual : '--' }}%)
              </small>
            </h5>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotaisNpsCard",
  props: {
    title: {
      type: String,
      default: "",
    },
    count: {
      type: [String, Number],
      default: "",
    },
    possiveis: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  computed: {
    percentual() {
      return ((this.count / this.possiveis) * 100).toFixed(1);
    },
  },
};
</script>
