<template>
    <span :class="['badge', `badge-${color}`, `badge-${size}`, { 'has-faturas': faturas.length > 0 }]"
        @click="visualizarFaturas()">
        {{ label }}
    </span>
    <button type="button" hidden data-bs-toggle="modal" data-bs-target="#modal-visualizar-faturas"
        ref="visualizarFaturasButton">
    </button>    
    <div id="modal-visualizar-faturas" class="modal fade" tabindex="-1" v-if="faturas.length > 0">
        <div class="modal-dialog modal-lg mt-lg-10">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 id="ModalLabel" class="modal-title">
                        Faturas em aberto ({{ faturas.length }})
                    </h5>
                </div>
                <div class="modal-body">                    
                    <div v-for="fatura in faturas" :key="fatura.id" class="card card-body bg-light mb-3">
                        <h6 class="text-center">
                            <span class="text-danger text-sm">
                                Vencida há {{ getDiffInDays(fatura.dueDate, new Date()) }} dias
                            </span>
                        </h6>
                        <div class="row justify-content-md-between">
                            <div class="col-md-4 mt-auto">
                                <h6 class="mb-0 text-start text-secondary">Numero</h6>
                                <h5 class="text-start mb-0">#{{ fatura.chargeId }}</h5>
                            </div>
                            <div class="col-lg-5 col-md-7">
                                <div class="row text-md-end text-start">
                                    <div class="col-md-6">
                                        <h6 class="text-secondary mb-0">Emissão:</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="text-dark mb-0">
                                            {{ filters.formatDate(fatura.createdAt) }}
                                        </h6>
                                    </div>
                                </div>
                                <div class="row text-md-end text-start">
                                    <div class="col-md-6">
                                        <h6 class="text-secondary mb-0">Vencimento:</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="text-dark mb-0">
                                            {{ filters.formatDate(fatura.dueDate) }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-md-between">
                            <div class="col-md-4">
                                <h6 class="text-start mb-0" v-if="fatura.chargeUrl">
                                    <a :href="fatura.chargeUrl" target="_blank" class="text-primary">
                                        Ver cobrança
                                    </a>
                                </h6>
                                <h6 class="text-start mb-0" v-if="fatura.billetUrl">
                                    <a :href="fatura.billetUrl" target="_blank" class="text-primary">
                                        Ver boleto
                                    </a>
                                </h6>
                            </div>
                            <div class="col-lg-5 col-md-7">
                                <div class="row text-md-end text-start">
                                    <div class="col-md-6">
                                        <h6 class="text-secondary mb-0">Valor Original:</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="text-dark mb-0">
                                            {{ filters.currency(fatura.amount) }}
                                        </h6>
                                    </div>
                                </div>
                                <div class="row text-md-end text-start">
                                    <div class="col-md-6">
                                        <h6 class="text-secondary mb-0">Valor Atualizado:</h6>
                                    </div>
                                    <div class="col-md-6">
                                        <h6 class="text-primary mb-0">
                                            {{ filters.currency(fatura.currentAmount) }}
                                        </h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-gradient-dark btn-sm" data-bs-dismiss="modal">
                        Fechar
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from 'moment';

export default {
    name: "BadgeStatusFinanceiro",
    props: {
        status: {
            type: String,
            default: 'verificando...'
        },
        faturas: {
            type: Array,
            default: () => []
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    computed: {
        label() {
            if (this.status === 'inadimplente') {
                return this.status.toUpperCase() + ` (${this.faturas.length})`;
            }

            return this.status.toUpperCase();
        },
        color() {
            const colors = {
                'verificando...': 'primary',
                'em dia': 'success',
                'inadimplente': 'danger',
            };

            return colors[this.status];
        }
    },
    methods: {
        visualizarFaturas() {
            if (this.faturas.length) {                
                this.$refs.visualizarFaturasButton.click();
            }
        },
        getDiffInDays(date1, date2) {
            const diffTime = moment(date2).diff(moment(date1), 'days');

            return diffTime;
        }
    }
}
</script>
<style lang="scss" scoped>
.has-faturas {
    cursor: pointer;
}
</style>